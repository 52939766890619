<template>
  <div class="about">
    <h1>This is an about View {{ $route.params.id }}</h1>
  </div>
</template>

<script>
export default {
  name: "Edit",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
