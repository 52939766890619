<template>
  <div class="about">
    <div class="row mt-5 text-left">
      <div class="col-sm-3"></div>
      <div class="col-sm-6 text-left">
        <div class="form-group">
          <div class="form-group">
            <div class="input-group mb-3">
              <span class="input-group-text">TARGET URL</span>
              <input
                type="text"
                class="form-control"
                placeholder="mysite.com/pageID=123456789"
                v-model="targetURL"
              />
            </div>
            {{ targetURL }}

            <iframe
              :src="targetURL"
              frameborder="0"
              id="preview-frame"
            ></iframe>

            <label for="shortenedLink">Custom Shortened Link</label>
            <div class="input-group mb-3" name="shortenedLink">
              <span class="input-group-text">https://leadto.it/</span>
              <input type="text" class="form-control" />
            </div>
            <button class="btn btn-primary" type="button" id="button-addon2">
              Create
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  data: function () {
    return {
      targetURL: "",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#preview-frame {
  width: 100%;
}
</style>
